import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import randomColor from "randomcolor";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  stacked: false,
  plugins: {
    title: {
      display: false,
      text: "",
    },
    legend: {
      display: false,
      position: "left bottom",
    },
  },
};

export default function SmoothedLineChart({ data }) {
  const labels = data[0]?.data.map(function (item, i) {
    return item.day + "-" + item.month;
  });
  const arr = [];
  const infos = {
    labels,
    
    datasets: data?.map(function (item, i) {
      const bright = randomColor({
        luminosity: "bright",
        format: "rgba", // Specify the format to include alpha channel
      });
        // Extract RGB values and set a custom alpha value
  const rgbValues = bright.match(/\d+/g);
  const backgroundColor = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, 0.2)`;
      let val = {name: item.name, color: bright };
      arr.push(val);

      return {
        label: item.name,
        data: item?.data.map(function (it, x) {
          return it?.nbr;
        }),
        borderColor: bright,
        backgroundColor: backgroundColor,
        tension: 0.4,
        fill: true,
        fillOpacity: 0.5
      };
    }),
  };
  const [legend, setLegend] = useState([]);
  useEffect(() => {
    setLegend(arr);
    console.log(legend);
  }, []);

  return (
    <>
      <Line options={options} data={infos} />
      <div className="legend-cadre">
        <div className="lg">
          {legend?.map(function (item, i) {
            return (
              <div className="color" key={i}>
                <span
                  className="lg-cadre"
                  style={{ background: arr[i]?.color }}
                ></span>
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
