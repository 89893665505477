import React, { useEffect, useRef, useState } from "react";
import "../../assets/sass/chartfromscratsh.scss";
import { Tooltip } from "reactstrap";

export default function Barchar({ data }) {
  const elem = useRef(null);
  const [margin, setMargin] = useState(0);
  const [max, setMax] = useState(0);
  const [step, setStep] = useState(0);
  const [disabledprev, setDisabledprev] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState([]);
  const [disablednext, setDisablednext] = useState(false);
  const toggle = (item) => {
    const newState = [...tooltipOpen];
    newState[item] = !tooltipOpen[item];
    setTooltipOpen(newState);
  };

  const scrolright = () => {
    setDisabledprev(false);
    let val = margin - 60;
    if (val === 0) {
      setDisabledprev(true);
    }
    setMargin(val);
  };

  const scrolleft = () => {
    if (!disabledprev) {
      let val = margin + 60;
      if (val === 0) {
        setDisabledprev(true);
      }
      setMargin(val);
    }
  };

  useEffect(() => {
    const ids = data?.ranking?.map((object) => object.detectionNumber);

    if (ids && ids.length > 0) {
      const currentMax = Math.max(...ids)
      let mean = data.mean || 0;
      let s = currentMax / 10;
      setStep(s);
      setMax(currentMax);
    }
  }, [data]);

  return (
    <>
      <div className="background" id="background">
      <div className="legend">
          <label>0</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 9), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 8), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 7), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 6), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 5), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 4), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 3), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step * 2), 0)}</label>
          <label>{Math.max(parseInt(max) - parseInt(step), 0)}</label>
          <label>{max}</label>
        </div>
        <div className="overflowhidden">
          <div
            className="charts"
            id="charts"
            ref={elem}
            style={{ marginLeft: margin + "px", height: "374px" }}
          >
                   <label
              className="line-red"
              style={{
                position: "absolute",
                height: "1px",
                width: '100%',
                borderBottom: "1px dashed red",
                bottom: 50 + "%",    // data?.mean + 35 + "px",
              }}
            >
            </label>
            {data?.ranking?.map((elem, index) => (
              <React.Fragment key={index}>
                <div className="bar" style={{ height: "100%" }}>
                  <span
                    id={"tip" + index}
                    style={{
                      height: (elem?.detectionNumber / max) * 100 + "%",
                      backgroundColor:
                        elem.detectionNumber > max / 2
                          ? "#12209f"
                          : "#d8dbf3",
                    }}
                  ></span>
                  <label style={{ paddingLeft: 20 }} title={elem?.name}>
                    {elem?.name?.slice(0, 10)}...
                  </label>
                </div>
                <Tooltip
                  isOpen={tooltipOpen[index]}
                  target={"tip" + index}
                  toggle={() => toggle(index)}
                >
                  {elem?.detectionNumber} détections
                </Tooltip>
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* ... (Your existing code) */}
      </div>
      <div className="legend-cadre">
      <div className="legend-cadre">
        <div className="lg">
          <div className="color">
            <span className="blue"></span>Forte diffusion
          </div>
          <div className="color">
            <span className="gray"></span>Faible diffusion
          </div>
          <div className="color">
            <span className="linered"></span>Moyenne de détections
          </div>
        </div>
        <div className="scrollbtn">
          <div
            className={disabledprev ? "prev disabled" : "prev"}
            onClick={() => scrolleft()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M9.06085 11.0652C9.1015 11.1059 9.13374 11.1541 9.15574 11.2072C9.17774 11.2603 9.18906 11.3173 9.18906 11.3748C9.18906 11.4322 9.17774 11.4892 9.15574 11.5423C9.13374 11.5954 9.1015 11.6436 9.06085 11.6843C9.0202 11.7249 8.97195 11.7572 8.91884 11.7792C8.86573 11.8012 8.80881 11.8125 8.75132 11.8125C8.69384 11.8125 8.63691 11.8012 8.5838 11.7792C8.53069 11.7572 8.48244 11.7249 8.44179 11.6843L4.06679 7.30929C4.02611 7.26865 3.99384 7.2204 3.97183 7.16729C3.94981 7.11418 3.93848 7.05725 3.93848 6.99975C3.93848 6.94226 3.94981 6.88533 3.97183 6.83222C3.99384 6.77911 4.02611 6.73086 4.06679 6.69022L8.44179 2.31522C8.52388 2.23313 8.63522 2.18701 8.75132 2.18701C8.86742 2.18701 8.97876 2.23313 9.06085 2.31522C9.14294 2.39732 9.18906 2.50866 9.18906 2.62475C9.18906 2.74085 9.14294 2.85219 9.06085 2.93429L4.99484 6.99975L9.06085 11.0652Z"
                fill={disabledprev ? "#727796" : "white"}
              />
            </svg>
          </div>
          <div
            className={disablednext ? "next disabled" : "next"}
            onClick={() => scrolright()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M4.93915 2.93478C4.8985 2.89413 4.86626 2.84587 4.84426 2.79276C4.82226 2.73965 4.81094 2.68273 4.81094 2.62524C4.81094 2.56776 4.82226 2.51084 4.84426 2.45773C4.86626 2.40462 4.8985 2.35636 4.93915 2.31571C4.9798 2.27506 5.02805 2.24282 5.08116 2.22082C5.13427 2.19882 5.19119 2.1875 5.24868 2.1875C5.30617 2.1875 5.36309 2.19882 5.4162 2.22082C5.46931 2.24282 5.51756 2.27506 5.55821 2.31571L9.93321 6.69071C9.97389 6.73135 10.0062 6.7796 10.0282 6.83271C10.0502 6.88582 10.0615 6.94275 10.0615 7.00025C10.0615 7.05774 10.0502 7.11467 10.0282 7.16778C10.0062 7.22089 9.97389 7.26914 9.93321 7.30978L5.55821 11.6848C5.47612 11.7669 5.36478 11.813 5.24868 11.813C5.13258 11.813 5.02124 11.7669 4.93915 11.6848C4.85706 11.6027 4.81094 11.4913 4.81094 11.3752C4.81094 11.2591 4.85706 11.1478 4.93915 11.0657L9.00516 7.00025L4.93915 2.93478Z"
                fill={disablednext ? "#727796" : "white"}
              />
            </svg>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
