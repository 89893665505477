import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Services from '../../services/global.service'

import axios from "axios";


const API_URL_NO_OCG = process.env.REACT_APP_WITHOUT_OGC || "";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    await AuthService.login(email, password).then(
      (response) => {
        navigate("/dashboard");
        Services.getAllDatasShow().then(
          axios.spread(
            (
              { data: genre },
              { data: title },
              { data: artist },
              { data: poi } ,
            ) => {
              localStorage.setItem('__genre',JSON.stringify(genre.response))
              localStorage.setItem('__title',JSON.stringify(title.response))
              localStorage.setItem('__artist',JSON.stringify(artist.response))
              localStorage.setItem('__poi',JSON.stringify(poi.response))
            }))
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          console.log(
            "Le mot de passe saisi est invalide, veuillez réessayer",
            error.response.data.message
          );
          setError(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 404) {
          console.log("Email saisi est invalide, veuillez réessayer");
          setError("User not found");
          return;
        }
        console.log("err", error);

        if (error.response && error.response.status === 401) {
          setError("Le mot de passe saisi est invalide, veuillez réessayer");
        }
      }
    );
  };

  return (
    <>
      <div className="container-fluid auth">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="banner-start"></div>
          </div>
          <div className="col-md-6 text-center">
            <div className="card w-60">
              <div className="card-header">
                <h1 className="text-h1 mb-0">Connexion</h1>
              </div>
              {error ? error : ''}
              <div className="card-body text-start">
                <form onSubmit={handleSubmit}>
                  <div className="mb-16">
                    <label htmlFor="email" className="text-label mb-1">
                      Nom d'utilisateur
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="email"
                      placeholder="Nom d'utilisateur"
                      onChange={(event) => {
                        setEmail(event.target.value);
                        setError("");
                      }}
                    />
                  </div>

                  <div className="mb-32">
                    <label htmlFor="password" className="text-label mb-1">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      className="input-form"
                      id="password"
                      placeholder="Mot de passe"
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>

                  <div className="mb-16">
                    <button className="button-primary">Connexion</button>
                  </div>

                  <div className="text-center">
                    <p className="text-label mb-2">
                      C’est votre première connexion ?{" "}
                    </p>
                    <Link to="/register" className="text-link">
                      Commencer
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
