import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./assets/sass/ui.scss";
import "./App.scss";
import Login from "./views/auth/Login";
import Register from "./views/auth/Register";
import Dashboard from "./views/dashboard/Dashboard";
import Rapports from "./views/dashboard/Rapports";
import ToggleSidebar from "./components/ToggleSidebar";
import Classements from "./views/dashboard/Classement";
import Titres from "./views/dashboard/Titres";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Balises from "./views/dashboard/Balises";
import Users from "./views/dashboard/Users";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route element={<ProtectedRoutes />}>
            <Route
              element={
                <>
                  <ToggleSidebar />
                  <Outlet />
                </>
              }
            >
              <Route path="/dashboard" element={<Dashboard />}  forceRefresh={true}/>
              <Route path="/dashboard/rapports" element={<Rapports />} />
              <Route path="/dashboard/classements" element={<Classements />} />
              <Route path="/dashboard/titres" element={<Titres />} />
              <Route path="/dashboard/balises" element={<Balises />} />
              <Route path="/dashboard/users" element={<Users />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
