import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import Logo from "../assets/images/icons/logo.svg";
import Question from "../assets/images/icons/question-icon.svg";

import Chartdonuta from "../assets/images/icons/chartdonut-a.svg";
import Chartdonut from "../assets/images/icons/chartdonut.svg";
import Chartbara from "../assets/images/icons/chartbar-a.svg";
import Chartbar from "../assets/images/icons/chartbar.svg";

import Microphonea from "../assets/images/icons/microphone-a.svg";
import Microphone from "../assets/images/icons/microphone.svg";

import Clipboardtexta from "../assets/images/icons/clipboardtext-a.svg";
import Clipboardtext from "../assets/images/icons/clipboardtext.svg";

import Filmreela from "../assets/images/icons/filmreel-a.svg";
import Filmreel from "../assets/images/icons/filmreel.svg";

import Identificationcarda from "../assets/images/icons/identificationcard-a.svg";
import Identificationcard from "../assets/images/icons/identificationcard.svg";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    document.body.classList.toggle("sb-sidenav-toggled", isOpen);
  }, [isOpen]);
  
  const exit = () => {
    setIsOpen(!isOpen);
    localStorage.clear();
    navigate(`/`);
    window.location.reload();
  };
  const LinkToggleOnMouseOver = ({ primaryImg, secondaryImg, label, url }) => {
    let imageRef = useRef(null);

    return (
      <NavLink
        to={url}
        className={({ isActive, isPending }) =>
          [
            isPending ? "pending" : "nav-link mb-12",
            isActive ? "active" : "",
          ].join(" ")
        }
        onMouseOver={() => {
          imageRef.current.src = secondaryImg;
        }}
        onMouseOut={() => {
          imageRef.current.src = primaryImg;
        }}
        end
      >
        {({ isActive }) => (
          <>
            <div className="sb-nav-link-icon pe-30">
              <img
                src={isActive ? secondaryImg : primaryImg}
                alt=""
                ref={imageRef}
              />
            </div>
            {label}
          </>
        )}
      </NavLink>
    );
  };

  return (
    <>
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-dark"
          id="sidenavAccordion"
          data-bs-theme="dark"
        >
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            aria-label="Close"
            onClick={() => setIsOpen(!isOpen)}
          ></button>
          <div className="sb-sidenav-menu">
            <div className="nav">
              <div className="sb-sidenav-menu-heading">
                <img src={Logo} alt="G-music" />
              </div>

              <div className="link">
                <LinkToggleOnMouseOver
                  key={1}
                  primaryImg={Chartdonut}
                  secondaryImg={Chartdonuta}
                  label="Dashboard"
                  url="/dashboard/"
                  alt=""
                />

                <LinkToggleOnMouseOver
                  key={2}
                  primaryImg={Chartbar}
                  secondaryImg={Chartbara}
                  label="Classements"
                  url="/dashboard/classements"
                  alt=""
                />

                <LinkToggleOnMouseOver
                  primaryImg={Microphone}
                  secondaryImg={Microphonea}
                  label="Titres"
                  url="/dashboard/titres"
                  alt=""
                />

                <LinkToggleOnMouseOver
                  key={3}
                  primaryImg={Clipboardtext}
                  secondaryImg={Clipboardtexta}
                  label="Rapports"
                  url="/dashboard/rapports/"
                  alt="/#"
                />

                <LinkToggleOnMouseOver
                  key={4}
                  primaryImg={Filmreel}
                  secondaryImg={Filmreela}
                  label="Balises"
                  url="/dashboard/balises"
                  alt=""
                />

                <LinkToggleOnMouseOver
                  key={5}
                  primaryImg={Identificationcard}
                  secondaryImg={Identificationcarda}
                  label="Utilisateurs"
                  url="/dashboard/users"
                  alt=""
                />

                <div
                  className="sb-sidenav-footer text-center mb-40"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <Link to="#" className="text-link text-white">
                    Déconnexion
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content modal-content-p-32">
            <div className="modal-header border-0 p-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center p-0">
              <img src={Question} className="mb-32" alt="Question" />
              <h1 className="text-h1">Vous souhaitez vous déconnecter ?</h1>
            </div>
            <div className="modal-footer justify-content-center border-0 p-0">
              <button
                type="button"
                className="button-outline-primary w-auto"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="button-primary w-auto"
                onClick={() => exit()}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
