import React, { useEffect, useState, useRef } from "react";
import Chevronbas from "../assets/images/icons/chevronbas.svg";
import axios from 'axios';
// import Daterange from "./Daterange";
import DropdownItemRadio from "./DropdownItemRadio";
import Services from "../services/global.service";
import DashboardScreen from '../assets/css/DashboardScreen.css'
import DateRangePicker from "react-bootstrap-daterangepicker";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import '../assets/sass/calander.scss';
import moment from 'moment'
import 'moment/locale/fr'
moment.locale('fr')


function DashboardSearch({data, setdata}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTermArtist, setSearchTermArtist] = useState('');
    const [searchTermTitle, setSearchTermTitle] = useState('');
    const [searchTermGenre, setSearchTermGenre] = useState('');
    const [searchTermPoi, setSearchTermPoi] = useState('');
    const rowSearch = useRef();


    const [suggestions, setSuggestions] = useState([]);
    const [titleSuggestions, setTitleSuggestions] = useState([]);
    const [artistSuggestions, setArtistSuggestions] = useState([]);
    const [genreSuggestions, setGenreSuggestions] = useState([]);
    const [selectionRange, setSelectionRange] = useState({
      startDate: "",
      endDate: "",
    });

    const applyfilter = async () => {
       // Set loading to true before starting the filter process
       setIsLoading(true);
      const endDateTmp   = new Date();
      const startDateTmp = new Date();
      let startDate = startDateTmp.toISOString().split('T')[0];
      let endDate   = endDateTmp.toISOString().split('T')[0];
      startDate = selectionRange.startDate === "" ? startDate : selectionRange.startDate;
      endDate   = selectionRange.endDate   === "" ? endDate   : selectionRange.endDate;
      let poi_uri = "";
      let title_uri = "";
      let genre_uri = "";
      let artist_uri = "";
        if(searchTermPoi.length  !== 0){
          let pois = localStorage.getItem('__poi')
          pois = JSON.parse(pois)
          let found = pois.find(function (element) {
            return element.name  === searchTermPoi;
          });
          if(found){
            poi_uri = "beaconSerialId="+found.id+"&"
          }
        }
        if(searchTermTitle.length  !== 0){
          let titles = localStorage.getItem('__title')
          titles = JSON.parse(titles)
          let found = titles.find(function (element) {
            return element.name  === searchTermTitle;
          });
          if(found){
            title_uri = "songId="+found.id+"&"
          }
        }
        if(searchTermArtist.length  !== 0){
          let artits = localStorage.getItem('__artist')
          artits = JSON.parse(artits)
          let found = artits.find(function (element) {
            return element.name  === searchTermArtist;
          });
          if(found){
            artist_uri = "authorId="+found.id+"&"
          }
        }
        if(searchTermGenre.length  !== 0){
          genre_uri = "genre="+searchTermGenre+"&"
        }
        try{
          let uri = "new-dashboard?"
          if(window.location.pathname.indexOf("/titres") > -1)
            uri = "ogc/title?";
          else if(window.location.pathname.indexOf("/classements") > -1)
              uri = "ogc/classement?";

          let url = uri + poi_uri+title_uri+genre_uri+artist_uri+"dateFrom="+startDate+"&dateTo="+endDate;
          let data = await Services.getAllwithoutocg(url);
          let response = data.response
          setdata(response)
          console.log(data)
              // Set loading to false after the filter process is complete
    setIsLoading(false);
        }catch(error){
          setdata([])
        }
    }

    const makesearchOthers = async (type, e) =>{
      const inputText = e.target.value;
      if(type === "title"){
        setSearchTermTitle(inputText);
      }else if(type === "artist"){
        setSearchTermArtist(inputText);
      }else if(type === "poi"){
        setSearchTermPoi(inputText)
      }
      else{
        setSearchTermGenre(inputText)
      }
      if(inputText.length < 3){
        return ;
      }
      try {
        let searchData = localStorage.getItem("__"+type)
        if(searchData.length !== 0){
          searchData = JSON.parse(searchData)
          const apiSuggestions = searchData?.filter(
            item =>
              item.name
                .toLowerCase()
                .indexOf(inputText.toLowerCase()) !== -1
          );
          if(type === "title"){
            setTitleSuggestions(apiSuggestions)
          }else if(type === "artist"){
            setArtistSuggestions(apiSuggestions);
          }else if(type === "poi"){
            setSuggestions(apiSuggestions)
          }else{
            setGenreSuggestions(apiSuggestions)
          }
        }else{
          setArtistSuggestions([])
          setTitleSuggestions([])
          setSuggestions([])
          setGenreSuggestions([])
        }
        const myDiv = rowSearch.current; // corresponding DOM node
        myDiv.classList.add("dash-search");
      }catch(error){
        console.error('Error fetching suggestions:', error);
        // Reset suggestions on error
        setSuggestions([]);
      }
    }

    const handleEvent = (event, picker) => {
      console.log(picker.startDate);
    };

    const handleCallback = (start, end, label) => {
      console.log("callbak datetime picker")
      let selectionRangeDate = {
        startDate : start?.format('YYYY-MM-DD'),
        endDate : end?.format('YYYY-MM-DD'),
      }
      setSelectionRange(selectionRangeDate)
    };



    const handleSuggestionClick = (type,suggestion) => {
        if(type === "artist"){
          setSearchTermArtist(suggestion);
        }else if(type === "title"){
          setSearchTermTitle(suggestion)
        }else if(type === "poi"){
          setSearchTermPoi(suggestion)
        }else{
          setSearchTermGenre(suggestion)
        }
        const myDiv = rowSearch.current; // corresponding DOM node
        myDiv.classList.remove("dash-search");
        // Clear suggestions
        setSuggestions([]);
        setTitleSuggestions([])
        setArtistSuggestions([])
        setGenreSuggestions([])
    }

    return (
        <>
            <nav className="sb-topnav navbar navbar-expand position-absolute">

                <div className="form-inline ms-auto" style={{position: "absolute",top: "9%",right:0,padding:20}}>
                    <div className="row g-3"  ref={rowSearch} >

                        {/* Search input */}
                        <div className="col-lg-7 col-md-12">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="input-form search-icon h-40 ps-5"
                                        id="nom"
                                        placeholder="Poi..."
                                        value={searchTermPoi}
                                        onChange={(e) => makesearchOthers("poi", e)}
                                    />
                                          {/* Display suggestions */}
                                          {Array.isArray(suggestions) && suggestions.length > 0 && (
                                            <ul className="list-group" style={{ marginTop: 0, maxHeight: "200px", overflowY: "scroll",border:"1px solid #eee" }}>
                                              {suggestions.map((suggestion, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() => handleSuggestionClick("poi",suggestion.name)}
                                                  className="list-group-item list-group-item-action"
                                                >
                                                  {/* Render the specific properties you want to display */}
                                                  {suggestion.name.slice(0, 10)}...
                                                  {/* Add more properties as needed */}
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="input-form search-icon h-40 ps-5"
                                        id="artist"
                                        placeholder="Artiste..."
                                        value={searchTermArtist}
                                        onChange={(e) => makesearchOthers("artist", e)}
                                    />
                                          {/* Display suggestions */}
                                          {Array.isArray(artistSuggestions) && artistSuggestions.length > 0 && (
                                            <ul className="list-group" style={{ marginTop: 0, maxHeight: "200px", overflowY: "scroll",border:"1px solid #eee" }}>
                                              {artistSuggestions.map((suggestion, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() => handleSuggestionClick("artist",suggestion.name)}
                                                  className="list-group-item list-group-item-action"
                                                  style={{border:0,borderBottom:"1px solid #eee"}}
                                                >
                                                  {/* Render the specific properties you want to display */}
                                                  {suggestion.name.slice(0, 10)}...
                                                  {/* Add more properties as needed */}
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="input-form search-icon h-40 ps-5"
                                        id="sector"
                                        value={searchTermTitle}
                                        placeholder="Titre..."
                                        onChange={(e) => makesearchOthers("title", e)}
                                    />
                                     {/* Display suggestions */}
                                     {Array.isArray(titleSuggestions) && titleSuggestions.length > 0 && (
                                            <ul className="list-group" style={{ marginTop: 0, maxHeight: "200px", overflowY: "scroll",border:"1px solid #eee" }}>
                                              {titleSuggestions.map((suggestion, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() => handleSuggestionClick("title",suggestion.name)}
                                                  className="list-group-item list-group-item-action"
                                                >
                                                  {/* Render the specific properties you want to display */}
                                                  {suggestion.name.slice(0, 10)}...
                                                  {/* Add more properties as needed */}
                                                </li>
                                              ))}
                                            </ul>
                                          )}

                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="input-form search-icon h-40 ps-5"
                                        id="genre"
                                        value={searchTermGenre}
                                        placeholder="Genre..."
                                        onChange={(e) => makesearchOthers("genre", e)}
                                 />
                                  {/* Display suggestions */}
                                  {Array.isArray(genreSuggestions) && genreSuggestions.length > 0 && (
                                            <ul className="list-group" style={{ marginTop: 0, maxHeight: "200px", overflowY: "scroll",border:"1px solid #eee" }}>
                                              {genreSuggestions.map((suggestion, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() => handleSuggestionClick("genre",suggestion.name)}
                                                  className="list-group-item list-group-item-action"
                                                >
                                                  {/* Render the specific properties you want to display */}
                                                  {suggestion.name.slice(0, 10)}...
                                                  {/* Add more properties as needed */}
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-7 col-md-12">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="input-form search-icon h-40 ps-5"
                                        id="nom"
                                        placeholder="Recherche..."
                                        onChange={makesearch}
                                    />
                                </div>


                            </div>
                        </div> */}
                        {/* Search input */}

                        {/* <div className="col-md">
                            <DropdownItemRadio list={list}  placeholderitem='Genre' radioselector='genre'/>
                        </div> */}
                        <div className="col-md">
                        <DateRangePicker
                            onEvent={handleEvent}
                            onCallback={handleCallback}
                            locale={'fr'}
                            initialSettings={{
                              showDropdowns: true,
                              locale: {
                                  'format': 'dd/MM/yyyy'
                              },
                            }}
                          >
                            <button className="form-dropdown text-btn w-100">
                              Période <img src={Chevronbas} alt="" />
                            </button>
                          </DateRangePicker>
                        </div>
                        <div className="col-md">
                        <button onClick={applyfilter} className="button-primary" disabled={isLoading}>
                       Appliquer
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin style={{marginLeft:10}}/> }
    </button>
                        </div>

                    </div>
                </div>
            </nav>
        </>
    );
}

export default DashboardSearch;
