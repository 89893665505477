import { format } from "date-fns";
import DataTable from "react-data-table-component";
import editicon from "../assets/images/edit.png";
import FilmReel from "../assets/images/FilmReel.png";
import ProfileIcon from "../assets/images/IdentificationCard.png";
import { useState } from "react";
import DropdownItemRadio from "./DropdownItemRadio";
import logo from "../assets/images/icons/profile.png";

var randomstring = require("randomstring");

export default function DatatableUsers({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  let list = ["Non affecté", "En marche", "Hors ligne", "Autre état"];
  const CustomTitle = ({ row }) => (
    <div className="text-legendr withicon">
      <img src={logo} width="30"></img>
    </div>
  );
  
  const handleSubmit = ({}) => {};
  const Editrow = ({ row }) => {
    console.log("Row Data:", row);
    // var newStr = row.beaconId.split('-');
    var newStr = randomstring.generate({
      length: 9,
      charset: "alphabetic",
    });

    return (
      <>
        <a
          className=" text-link "
          data-bs-toggle="modal"
          data-bs-target={"#" + newStr + "modal"}
        >
          Modifer
        </a>

        <div
          className="modal fade"
          id={newStr + "modal"}
          tabIndex="-1"
          aria-labelledby={newStr + "modalLabel"}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content modal-content-p-32">
              <div className="modal-header border-0 p-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-0">
                <h1 className="text-h1">
                  <img src={ProfileIcon} style={{width:41}} /> Modifier le profil
                </h1>
                <br></br>
                <p>ID : {row?.beaconId}</p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-16">
                    <label htmlFor="proprietaire" className="text-label mb-1">
                      Nom
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="name"
                      value={row.first_name}
                      placeholder="Nom"
                      onChange={(event) => {
                        // setEmail(event.target.value);
                        setError("");
                      }}
                    />
                  </div>

                  <div className="mb-32">
                    <label htmlFor="secteur" className="text-label mb-1">
                    Prénom
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="prenom"
                      placeholder="Prénom"
                      value={row?.first_name}
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>

                  <div className="mb-32">
                    <label htmlFor="zone" className="text-label mb-1">
                    Poste
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="Poste"
                      placeholder="Poste"
                      value={row?.position}
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                    E-mail
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="email"
                      placeholder="E-mail"
                    
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                    Mot de passe actuel
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="CurrentPassword"
                      placeholder="Mot de passe actuel"
                  
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                    Nouveau mot de passe
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="NewPassword"
                      placeholder="Nouveau mot de passe"
                     
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                    Confirmation du mot de passe
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="ConfirmNewPassword"
                      placeholder="Confirmation du mot de passe"
                
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                    Accès
                    </label>
                    <DropdownItemRadio list={list} placeholderitem='Etat' radioselector='etat'/>
                  </div>
                </form>
              </div>
              <div className="modal-footer justify-content-center border-0 p-0">
                <button
                  type="button"
                  className="button-outline-primary w-auto"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="button-primary w-auto"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    // {
    //   name: "ID",
    //   selector: (row) => row.id,
    // },
    {
      name: "",
      width :"150px",
      selector: (row) => row?.username,
      cell: (row) => <CustomTitle row={row} />,
    },
    {
      name: "Nom",
      selector: (row) => row?.last_name,
    },
    {
      name: "Prénom",
      selector: (row) => row?.first_name,
    },
    {
      name: "Poste",
      selector: (row) => row?.username,
    },
    {
      name: "Accès",
      selector: (row) => row?.position,
    },
        {
      name: "",
      cell: (row) => <Editrow row={row} />,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "16px 24px",
        justifyContent: "center",
        backgroundColor: "#F7F8FD",
      },
    },
    cells: {
      style: {
        padding: "16px 24px",
        justifyContent: "center",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        backgroundColor: "#fff",
        color: "#0B0F37",
        textAlign: "center",
        fontFamily: "Urbanist, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
        paginationComponentOptions={{
          rowsPerPageText: "Ligne par page:",
          rangeSeparatorText: "sur",
        }}
      />
    </>
  );
}
