import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import Chevronbas from "../assets/images/icons/chevronbas.svg";

import '../assets/sass/calander.scss';

import moment from 'moment'
import 'moment/locale/fr' 
moment.locale('fr')


function Daterange(props) {
  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };
  const handleCallback = (start, end, label) => {
    console.log("callbak datetime picker")
    // this.state?.starDate = start?.format('YYYY-MM-DD')
    // this.starDate = start?.format('YYYY-MM-DD')
    let selectionRangeDate = {
      start : start?.format('YYYY-MM-DD'),
      end : end?.format('YYYY-MM-DD'),
    }
    console.log("start "+ start?.format('YYYY-MM-DD'));
    console.log("end "+   end?.format('MM/DD/YYYY (dddd)'));
  };

  return (
    <DateRangePicker
      onEvent={handleEvent}
      onCallback={handleCallback}
      locale={'fr'}
      initialSettings={{
        showDropdowns: true,
        locale: {
            'format': 'dd/MM/yyyy'
        },
      }}
    >
      <button className="form-dropdown text-btn w-100">
        Période <img src={Chevronbas} alt="" />
      </button>
    </DateRangePicker>
  );
}

export default Daterange;
