import React, { useState } from "react";

function Progressbar({ data }) {
  const [total, setTotal] = useState();
  return (
    <>
      {data?.slice(0, 5).map((elem, i) => (
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="box-chart text-link text-bleumarine900">{i + 1}</div>
          <div className="w-100">
            <div className="d-flex justify-content-between mb-1">
              <div className="text-legendr">
                {elem?.SongTitle || elem?.Genre || elem?.AuthorName}
              </div>
              <div className="text-dmsans">{elem?.count}</div>
            </div>
            <div
              className="progress bg-bleu100"
              role="progressbar"
              aria-label="Basic example"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ height: "8px" }}
            >
              <div
                className="progress-bar bg-bleu500"
                style={{ width: elem?.percentage + "%" }}
              ></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default Progressbar;
