import React, { useEffect, useState } from "react";

import Sidebar from "../../components/Sidebar";

import DashboardSearch from "../../components/DashboardSearch";
import Services from "../../services/global.service";
import DatatableBalises from "./../../components/DatatableBalises";
import FilmReel from "../../assets/images/FilmReel.png";
import DropdownItemRadio from "../../components/DropdownItemRadio";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Atabs = () => {

  return (
    <ul className="nav nav-tabs naving" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="online-tab"
          data-bs-toggle="tab"
          data-bs-target="#online"
          type="button"
          role="tab"
          aria-controls="online"
          aria-selected="true"
        >
          En ligne
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="offline-tab"
          data-bs-toggle="tab"
          data-bs-target="#offline"
          type="button"
          role="tab"
          aria-controls="offline"
          aria-selected="false"
        >
          Hors ligne
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="all-tab"
          data-bs-toggle="tab"
          data-bs-target="#all"
          type="button"
          role="tab"
          aria-controls="all"
          aria-selected="false"
        >
          Tout
        </button>
      </li>
    </ul>
  );
};

const Balise = () => {
  const endDateTmp   = new Date();
  const startDateTmp = new Date();
  const startDate = startDateTmp.toISOString().split('T')[0]; 
  const endDate   = endDateTmp.toISOString().split('T')[0]; 
  const navigate = useNavigate();

  const [data, setdata] = useState([]);
  const [endpoint, setEndpoint] = useState("beacons_split?&limit=10000");

  const loadData = async () => {
    let data = await Services.getAllwithoutocg(endpoint);
    setdata(data);
  };
  
  useEffect(() => {
    let endpointTmp = endpoint.split("?")[0];
    if (localStorage.getItem(endpointTmp.replace("/", "_")) !== null) {
      let data = JSON.parse(localStorage.getItem(endpointTmp.replace("/", "_")));
      setdata(data);
    }
    loadData();
  }, [endpoint]);

  // return (
  // );

  return (
    <>
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main className="px-48">
            <div className="container-fluid">
              <h1 className="text-h1 mt-32 mb-32 ps-0">Liste des balises</h1>

              <Atabs />

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="online"
                  role="tabpanel"
                  aria-labelledby="online-tab"
                >
                  <div className="mb-40">
                    <DatatableBalises data={data?.online}/>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="offline"
                  role="tabpanel"
                  aria-labelledby="offline-tab"
                >
                  <DatatableBalises data={data?.offline} />
                </div>
                <div
                  className="tab-pane fade"
                  id="all"
                  role="tabpanel"
                  aria-labelledby="all-tab"
                >
                  <DatatableBalises data={data?.all}/>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Balise;
