import React, { useState, useEffect } from "react";

import Sidebar from "../../components/Sidebar";

import DashboardSearch from "../../components/DashboardSearch";
import DatatableTitresidentifies from "../../components/DatatableTitresidentifies";
import DatatableTitresnonidentifies from "../../components/DatatableTitresnonidentifies";
import Services from "../../services/global.service";

const Atabs = () => {
  return (
    <ul className="nav nav-tabs naving" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Titres identifiés
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Titres non-identifiés
        </button>
      </li>
    </ul>
  );
};

const Titres = () => {
  const endDateTmp   = new Date();
  const startDateTmp = new Date();
  const startDate = startDateTmp.toISOString().split('T')[0]; 
  const endDate   = endDateTmp.toISOString().split('T')[0]; 

  const [data, setdata] = useState([]);
  const [endpoint, setEndpoint] = useState("title?dateFrom="+ startDate + "&dateTo="+ endDate + "&limit=10000");

  const loadData = async () => {
    let data = await Services.getAll(endpoint);
    setdata(data.response);
  };
  
  useEffect(() => {
    let endpointTmp = endpoint.split("?")[0];
    if (localStorage.getItem(endpointTmp.replace("/", "_")) !== null) {
      let data = JSON.parse(localStorage.getItem(endpointTmp.replace("/", "_")));
      setdata(data.response);
    }
    loadData();
  }, [endpoint]);
  
  return (
    <>
      <DashboardSearch data={data} setdata={setdata} />
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main className="px-48">
            <div className="container-fluid">
              <h1 className="text-h1 mt-32 mb-32 ps-0">Les titres</h1>
              <Atabs />
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="mb-40">
                    <DatatableTitresidentifies data={data?.recognized}/>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <DatatableTitresnonidentifies  data={data?.not_recognized}/>
                </div>
              </div>{" "}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Titres;
