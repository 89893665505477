import DataTable from 'react-data-table-component';
import { format } from "date-fns";


const columns = [
    {
        name: 'Lieu',
        selector: row => row["Lieu d'écoute"],
        grow: 1.5,
    },
    {
        name: 'Artiste',
        selector: row => row["Nom de l'auteur"],
        grow: 1.5,
    },
    {
        name: 'Titre',
        selector: row => row["Titre du son"],
        grow:2
    },
    {
        name: 'Genre',
        selector: row => row["Genre"],
        grow: 1.5,
    },
    {
        name: 'Date de détection',
        selector: row => format(new Date(row["Date d'écoute"]), "dd/MM/yyyy HH:mm"),
        grow: 1.5,
        sortable : true,
    },
    {
        name: 'Durée (en seconde)',
        cell: row => row["Durée en second"],
        grow: 0.5,
    },
];

const customStyles = {
    headCells: {
        style: {
            fontSize: '20px',
            fontWeight: 'bold',
            padding: '16px 24px',
            justifyContent: 'center',
            backgroundColor: '#F7F8FD'
        },
    },
    cells: {
        style: {
            padding: '16px 24px',
            justifyContent: 'center',
        },
    },
    rows: {
        style: {
            cursor: 'pointer',
            backgroundColor: "#fff",
            color: '#0B0F37',
            textAlign: 'center',
            fontFamily: 'Urbanist, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '22px',
        },
    }
}

export default function DatatableTapports({data}) {

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                paginationComponentOptions={{
                  rowsPerPageText: "Ligne par page:",
                  rangeSeparatorText: "sur",
                }}
            />
        </>
    );
};