import { format } from "date-fns";
import DataTable from "react-data-table-component";
import editicon from "../assets/images/edit.png";
import FilmReel from "../assets/images/FilmReel.png";

import { useState } from "react";
import DropdownItemRadio from "./DropdownItemRadio";
var randomstring = require("randomstring");

export default function DatatableBalises({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  let list = ["Non affecté", "En marche", "Hors ligne", "Autre état"];
  const CustomTitle = ({ row }) => {
    if (row.onlineState === 0) {
      return <div className="text-legendr hors-ligne">Hors ligne</div>;
    } else {
      return <div className="text-legendr en-marche">En ligne</div>;
    }
  };
  const CustomState = ({ row }) => {
    if (row.onlineState === 0) {
      return "Hors ligne";
    } else {
      return "En ligne";
    }
  };
  const handleSubmit = ({}) => {};
  const Editrow = ({ row }) => {
    // var newStr = row.beaconId.split('-');
    var newStr = randomstring.generate({
      length: 9,
      charset: "alphabetic",
    });

    var recorddate = format(new Date(row.creationDateTime), "dd/MM/yyyy");

    // alert(recorddate);
    
    return (
      <>
        <div
          className=" text-center "
          data-bs-toggle="modal"
          data-bs-target={"#" + newStr + "modal"}
        >
          <img src={editicon} />
        </div>

        <div
          className="modal fade"
          id={newStr + "modal"}
          tabIndex="-1"
          aria-labelledby={newStr + "modalLabel"}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content modal-content-p-32">
              <div className="modal-header border-0 p-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-0">
                <h1 className="text-h1">
                  <img src={FilmReel} /> Modifier une balise
                </h1>
                <br></br>
                <h2>ID : {row?.beaconSerialId}</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-16">
                    <label htmlFor="proprietaire" className="text-label mb-1">
                      Nom du propriétaire
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="proprietaire"
                      value={row.poi.name}
                      placeholder="Nom du propriétaire"
                      onChange={(event) => {
                        // setEmail(event.target.value);
                        setError("");
                      }}
                    />
                  </div>

                  <div className="mb-32">
                    <label htmlFor="secteur" className="text-label mb-1">
                      Secteurs
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="secteur"
                      placeholder="secteur"
                      value={row?.poi?.city}
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>

                  <div className="mb-32">
                    <label htmlFor="zone" className="text-label mb-1">
                      Zones
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="zone"
                      placeholder="zone"
                      value={row?.poi.zone}
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                      Sous-zones
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      id="sous-zone"
                      placeholder="sous-zone"
                      value={row?.poi.zone}
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                      Dates d’enregistrement
                    </label>
                    <input
                      type="date"
                      className="input-form"
                      id="enregistrement"
                      placeholder="enregistrement"
                      value={new Date(row.creationDateTime)}
                    />
                  </div>
                  <div className="mb-32">
                    <label htmlFor="sous-zone" className="text-label mb-1">
                      Etat
                    </label>
                    {/* <DropdownItemRadio list={list} placeholderitem='Etat' radioselector='etat'/> */}
                    <input
                      type="text"
                      className="input-form"
                      id="etat"
                      placeholder="etat"
                      value={<CustomState row={row} />}
                      onChange={(event) => {
                        // setPassword(event.target.value);
                        setError("");
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer justify-content-center border-0 p-0">
                <button
                  type="button"
                  className="button-outline-primary w-auto"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="button-primary w-auto"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      name: "Lieux",
      selector: (row) => row.poi.name,
      grow: 3,
      sortable : true,
    },
    {
      name: "Balise ID",
      selector: (row) => row.beaconSerialId,
      grow: 3,
      sortable : true,
    },
    {
      name: "Secteur",
      selector: (row) => row?.poi?.city,
      grow: 1,
      sortable : true,
    },
    {
      name: "Zone",
      selector: (row) => row?.poi.zone,
      grow: 1.5,
      sortable : true,
    },
    {
      name: "Dernière connexion",
      selector: (row) => format(new Date(row.lastOnlineDateTime), "dd/MM/yyyy HH:mm"),
      grow: 1.5,
      sortable : true,
    },
    // {
    //   name: "Modification",
    //   selector: (row) =>
    //     format(new Date(row.modificationDateTime), "dd/MM/yyyy"),
    // },
    {
      name: "Etat",
      selector: (row) => row.serviceState,
      cell: (row) => <CustomTitle row={row} />,
      grow: 1,
    },
    {
      name: "",
      cell: (row) => <Editrow row={row} />,
      grow: 0.5,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "16px 24px",
        justifyContent: "center",
        backgroundColor: "#F7F8FD",
      },
    },
    cells: {
      style: {
        padding: "16px 24px",
        justifyContent: "center",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        backgroundColor: "#fff",
        color: "#0B0F37",
        textAlign: "center",
        fontFamily: "Urbanist, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "22px",
      },
    },
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
        paginationComponentOptions={{
          rowsPerPageText: "Ligne par page:",
          rangeSeparatorText: "sur",
        }}
      />
    </>
  );
}
