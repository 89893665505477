import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Piechart = ({ counts, recognized, not_recognized }) => {
    useEffect(() => {
        let root = am5.Root.new("chartpie");
        root._logo.dispose();
        root.setThemes([am5themes_Animated.new(root)]);

        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
                radius: am5.percent(95),
                innerRadius: am5.percent(75),
            })
        );

        // Create series
        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: "Series",
                categoryField: "country",
                valueField: "sales",
            })
        );

        series.slices.template.setAll({
            templateField: "sliceSettings"
        });

        series.labels.template.setup = function (label, dataItem) {
            label.events.on("dataitemchanged", function (ev) {
                label.set("background", am5.RoundedRectangle.new(root, {
                    fill: ev.target.dataItem.get("slice").get("fill")
                }));
            });
        }

        series.data.setAll([{
            country: "Titres  identifiés",
            sales: recognized,
            fontWeight: 700,
            sliceSettings: {
                fill: am5.color(0x12209F),
                stroke: am5.color(0x12209F)
            }
        }, {
            country: "Titres non identifiés",
            sales: not_recognized,
            sliceSettings: {
                fill: am5.color(0x2EDDB3),
                stroke: am5.color(0x2EDDB3)

            }
        }]);

        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("forceHidden", true);
        series.slices.template.set("strokeWidth", 1);

        let label = series.children.push(am5.Label.new(root, {
            text: recognized,
            fontFamily: "Urbanist, sans-serif",
            fontSize: 26,
            fontWeight: 700,
            centerX: am5.percent(50),
            centerY: am5.percent(70),
            populateText: true,
            oversizedBehavior: "fit"
        }));
        let label2 = series.children.push(am5.Label.new(root, {
            text: "/ "+counts,
            fontFamily: "Urbanist, sans-serif",
            fontSize: 20,
            fontWeight: 400,
            centerX: am5.percent(50),
            centerY: am5.percent(0),
            populateText: true,
            oversizedBehavior: "fit",
            color:"#cccccc"
        }));

        series.onPrivate("width", function (width) {
            label.set("maxWidth", width * 0.4);
            label2.set("maxWidth", width * 0.4);
        });

        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            layout: root.horizontalLayout,
            
        }));
        legend.valueLabels.template.set("forceHidden", true);
        legend.data.setAll(series.dataItems);
        // Add cursor
        // chart.set("cursor", am5xy.XYCursor.new(root, {}));


        // Cleanup function
        return () => {
            if (root) {
                root.dispose();
            }
        };
    }, [counts]); // Empty dependency array means this effect runs once, similar to componentDidMount

    return (
        <div id="chartpie" style={{ width: "100%", height: "340px" }}></div>
    );
};

export default Piechart;
