import DataTable from 'react-data-table-component';
import artiste from '../assets/images/icons/music.png';
import { format } from "date-fns";

const CustomTitle = ({ row }) => (
    <div className='text-legendr withicon'>
        <img src={artiste}></img> {row.songTitle}
    </div>
);

const columns = [
    
    {
        name: 'Titre',
        selector: row => row.songTitle,
        cell: row => <CustomTitle row={row} />,
        grow : 1.5,
        sortable : true,
    },
    {
        name: 'Artiste',
        selector: row => row.authorName,
        grow : 1.5,
        sortable : true,
    },
    {
        name: 'Lieux',
        selector: row => row.poiName,
        grow : 1.5,
        sortable : true,

    },
    {
        name: 'Genre',
        selector: row => row.genre,
        grow : 1.5,
        sortable : true,
    },
    {
        name: 'Date de détection',
        selector: row => format(new Date(row.time), "dd/MM/yyyy HH:mm"),
        grow : 1.5,
        sortable : true,
    },
    {
        name: 'Durée (en second)',
        selector: row => row.playbackTime,
        sortable : true,
    },
    // {
    //     name: 'Nombres de détections',
    //     selector: row => row.year,
    // },
];

const customStyles = {
    headCells: {
        style: {
            fontSize: '20px',
            fontWeight: 'bold',
            padding: '16px 24px',
            justifyContent: 'center',
            backgroundColor: '#F7F8FD'
        },
    },
    cells: {
        style: {
            padding: '16px 24px',
            justifyContent: 'center',
        },
    },
    rows: {
        style: {
            cursor: 'pointer',
            backgroundColor: "#fff",
            color: '#0B0F37',
            textAlign: 'center',
            fontFamily: 'Urbanist, sans-serif',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '22px',
        },
    }
}

export default function DatatableTitresidentifies({data}) {

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                paginationComponentOptions={{
                  rowsPerPageText: "Ligne par page:",
                  rangeSeparatorText: "sur",
                }}
                    />
        </>
    );
};