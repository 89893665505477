import React from "react";
import { Link } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import DatatableTapports from "../../components/DatatableTapports";
import { CSVLink, CSVDownload } from "react-csv";
import Chevronbas from "../../assets/images/icons/chevronbas.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import ShieldCheck from "../../assets/images/icons/shieldcheck.svg";
import Daterange from "../../components/Daterange";
import { useState, useEffect } from "react";
import Services from "./../../services/global.service";

import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import '../../assets/sass/calander.scss';
import moment from 'moment'
import 'moment/locale/fr'
moment.locale('fr')


const Rapports = () => {
  const endDateTmp   = new Date();
  const startDateTmp = new Date();
  const startDate = startDateTmp.toISOString().split('T')[0];
  const endDate   = endDateTmp.toISOString().split('T')[0];
  const [isLoading, setIsLoading] = useState(false);

  const filename = startDate + "_" + endDate + "_Export.csv";
  const [data, setdata] = useState([]);
  const [endpoint, setEndpoint] = useState("export?dateFrom="+ startDate + "&dateTo="+ endDate + "&limit=4000");

  const [suggestions, setSuggestions] = useState([]);
    const [titleSuggestions, setTitleSuggestions] = useState([]);
    const [artistSuggestions, setArtistSuggestions] = useState([]);
    const [genreSuggestions, setGenreSuggestions] = useState([]);

    const [searchTermArtist, setSearchTermArtist] = useState('');
    const [searchTermTitle, setSearchTermTitle] = useState('');
    const [searchTermGenre, setSearchTermGenre] = useState('');
    const [searchTermPoi, setSearchTermPoi] = useState('');

  const [selectionRange, setSelectionRange] = useState({
    startDate: "",
    endDate: "",
  });

  const loadData = async () => {
    let data = await Services.getAllwithoutocg(endpoint);
    if (data?.response) {
      setdata(data.response);
    }
  };

  const makesearchOthers = async (type, e) =>{
    const inputText = e.target.value;
    if(type === "title"){
      setSearchTermTitle(inputText);
    }else if(type === "artist"){
      setSearchTermArtist(inputText);
    }else if(type === "poi"){
      setSearchTermPoi(inputText)
    }
    else{
      setSearchTermGenre(inputText)
    }
    if(inputText.length < 3){
      return ;
    }
    try {
      let searchData = localStorage.getItem("__"+type)
      if(searchData.length !== 0){
        searchData = JSON.parse(searchData)
        const apiSuggestions = searchData?.filter(
          item =>
            item.name
              .toLowerCase()
              .indexOf(inputText.toLowerCase()) !== -1
        );
        if(type === "title"){
          setTitleSuggestions(apiSuggestions)
        }else if(type === "artist"){
          setArtistSuggestions(apiSuggestions);
        }else if(type === "poi"){
          setSuggestions(apiSuggestions)
        }else{
          setGenreSuggestions(apiSuggestions)
        }
      }else{
        setArtistSuggestions([])
        setTitleSuggestions([])
        setSuggestions([])
        setGenreSuggestions([])
      }
    }catch(error){
      console.error('Error fetching suggestions:', error);
      // Reset suggestions on error
      setSuggestions([]);
    }
  }

  const applyfilter = async () => {
    // Set loading to true before starting the filter process
    setIsLoading(true);
    const endDateTmp   = new Date();
    const startDateTmp = new Date();
    let startDate = startDateTmp.toISOString().split('T')[0];
    let endDate = endDateTmp.toISOString().split('T')[0];
    startDate = selectionRange.start === undefined ? startDate : selectionRange.start;
    endDate = selectionRange.end === undefined ? endDate : selectionRange.end;
    let poi_uri = "";
    let title_uri = "";
    let genre_uri = "";
    let artist_uri = "";
      if(searchTermPoi.length  !== 0){
        let pois = localStorage.getItem('__poi')
        pois = JSON.parse(pois)
        let found = pois.find(function (element) {
          return element.name  === searchTermPoi;
        });
        if(found){
          poi_uri = "beaconSerialId="+found.id+"&"
        }
      }
      if(searchTermTitle.length  !== 0){
        let titles = localStorage.getItem('__title')
        titles = JSON.parse(titles)
        let found = titles.find(function (element) {
          return element.name  === searchTermTitle;
        });
        if(found){
          title_uri = "songId="+found.id+"&"
        }
      }
      if(searchTermArtist.length  !== 0){
        let artits = localStorage.getItem('__artist')
        artits = JSON.parse(artits)
        let found = artits.find(function (element) {
          return element.name  === searchTermArtist;
        });
        if(found){
          artist_uri = "songId="+found.id+"&"
        }
      }
      if(searchTermGenre.length  !== 0){
        genre_uri = "genre="+searchTermGenre+"&"
      }

      try{
        let url = "export?"+poi_uri+title_uri+genre_uri+artist_uri+"dateFrom="+startDate+"&dateTo="+endDate+"&limit=4000"
        let data = await Services.getFile(url);
        let response = data.response
        // setdata(response)
        setIsLoading(false);
      }catch(error){
        setdata([])
      }
  }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log("callback datetime picker")
    let selectionRangeDate = {
      start : start?.format('YYYY-MM-DD'),
      end : end?.format('YYYY-MM-DD'),
    }
    setSelectionRange(selectionRangeDate)
  };

  const handleSuggestionClick = (type,suggestion) => {
    if(type === "artist"){
      setSearchTermArtist(suggestion);
    }else if(type === "title"){
      setSearchTermTitle(suggestion)
    }else if(type === "poi"){
      setSearchTermPoi(suggestion)
    }else{
      setSearchTermGenre(suggestion)
    }
    // Clear suggestions
    setSuggestions([]);
    setTitleSuggestions([])
    setArtistSuggestions([])
    setGenreSuggestions([])
}

  useEffect(() => {
    let endpointTmp = endpoint.split("?")[0];
    if (localStorage.getItem(endpointTmp.replace("/", "_")) !== null) {
      let data = JSON.parse(localStorage.getItem(endpointTmp.replace("/", "_")));
      setdata(data.response);
    }
    // loadData();
  }, [endpoint]);

  return (
    <>
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main className="px-48">
            <div className="container-fluid">
              <h1 className="text-h1 mb-40">Les rapports</h1>

              <div className="card mb-40">
                <div className="card-header">
                  <h2 className="text-h2 text-center">Création d’un rapport</h2>
                </div>

                <div className="card-body">
                  <div className="row g-0 align-items-end pb-8">
                    <div className="col-lg-4 col-sm-12">
                      <div className="mb-lg-0 mb-2">
                        <label htmlFor="lieu" className="text-label mb-1">
                          Lieu de détections
                        </label>
                        <input
                          type="text"
                          className="input-form h-40"
                          id="lieu"
                          value={searchTermPoi}
                          onChange={(e) => makesearchOthers("poi", e)}
                          placeholder="Lieu de  détections"
                        />
                         {/* Display suggestions */}
                         {Array.isArray(suggestions) && suggestions.length > 0 && (
                          <ul className="list-group" style={{marginTop:0,position:"absolute"}}>
                            {suggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                onClick={() => handleSuggestionClick("poi",suggestion.name)}
                                className="list-group-item list-group-item-action"
                              >
                                {/* Render the specific properties you want to display */}
                                {suggestion.name}
                                {/* Add more properties as needed */}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                      <div className="mb-lg-0 mb-2 ms-24">
                        <label htmlFor="genre" className="text-label mb-1">
                          Genre musical
                        </label>
                        <input
                          type="text"
                          className="input-form h-40"
                          id="genre"
                          placeholder="Genre musical"
                          value={searchTermGenre}
                          onChange={(e) => makesearchOthers("genre", e)}
                        />
                         {/* Display suggestions */}
                         {Array.isArray(genreSuggestions) && genreSuggestions.length > 0 && (
                          <ul className="list-group" style={{marginTop:0,position:"absolute"}}>
                            {genreSuggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                onClick={() => handleSuggestionClick("genre",suggestion.name)}
                                className="list-group-item list-group-item-action"
                              >
                                {/* Render the specific properties you want to display */}
                                {suggestion.name}
                                {/* Add more properties as needed */}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                      <div className="mb-lg-0 mb-2 ms-24">
                        <label htmlFor="nom" className="text-label mb-1">
                          Nom de l’artiste
                        </label>
                        <input
                          type="text"
                          className="input-form h-40"
                          id="nom"
                          placeholder="Nom de l’artiste"
                          value={searchTermArtist}
                          onChange={(e) => makesearchOthers("artist", e)}
                        />
                         {/* Display suggestions */}
                         {Array.isArray(artistSuggestions) && artistSuggestions.length > 0 && (
                          <ul className="list-group" style={{marginTop:0,position:"absolute"}}>
                            {artistSuggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                onClick={() => handleSuggestionClick("artist",suggestion.name)}
                                className="list-group-item list-group-item-action"
                              >
                                {/* Render the specific properties you want to display */}
                                {suggestion.name}
                                {/* Add more properties as needed */}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row g-0 justify-content-center">
                    <div className="col-lg-4 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="Titre" className="text-label mb-1">
                          Titre
                        </label>
                        <input
                          type="text"
                          className="input-form h-40"
                          id="Titre"
                          placeholder="Titre"
                          value={searchTermTitle}
                          onChange={(e) => makesearchOthers("title", e)}
                        />
                        {Array.isArray(titleSuggestions) && titleSuggestions.length > 0 && (
                        <ul className="list-group" style={{marginTop:0,position:"absolute"}}>
                          {titleSuggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionClick("title",suggestion.name)}
                              className="list-group-item list-group-item-action"
                            >
                              {/* Render the specific properties you want to display */}
                              {suggestion.name}
                              {/* Add more properties as needed */}
                            </li>
                          ))}
                        </ul>
                      )}
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-12 ms-24">
                      <div className="mb-3">
                        <label className="text-label mb-1">&nbsp;</label>
                        <DateRangePicker
                            onEvent={handleEvent}
                            onCallback={handleCallback}
                            locale={'fr'}
                            initialSettings={{
                              showDropdowns: true,
                              locale: {
                                  'format': 'dd/MM/yyyy'
                              },
                            }}
                          >
                            <button className="form-dropdown text-btn w-100">
                              Période <img src={Chevronbas} alt="" />
                            </button>
                          </DateRangePicker>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-12 ms-24">
                      <div className="mb-3">
                        <label className="text-label mb-1">&nbsp;</label>
                        <button onClick={applyfilter} className="button-primary" disabled={isLoading}>
                        Générer
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin style={{marginLeft:10}}/> }
    </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-32">
                <div>
                  <h3 className="text-h2">Votre rapport</h3>
                </div>
                <div>
                  <CSVLink className="text-link" data={data} separator=";" filename={filename}>
                    Télécharger votre rapport
                  </CSVLink>
                </div>
              </div>

              {/* DataTableTest */}
              <div className="mb-40">
                <DatatableTapports data={data} />
              </div>
            </div>

            <div
              className="modal fade"
              id="DownloadModal"
              tabIndex="-1"
              aria-labelledby="DownloadModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content modal-content-p-32">
                  <div className="modal-header border-0 p-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center p-0">
                    <img src={ShieldCheck} className="mb-32" alt="Question" />
                    <h1 className="text-h1 mb-32">
                      Votre rapport a bien été téléchargé !
                    </h1>
                  </div>
                  <div className="modal-footer justify-content-center border-0 p-0">
                    <button type="button" className="button-primary w-auto">
                      Retour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Rapports;
