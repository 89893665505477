import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_BASE_URL;
const API_OGC = process.env.REACT_APP_WITHOUT_OGC;
const token = localStorage.getItem('token')

const headersFiles = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem('token'), 
    },
  };
  const login = (email, password) => {


    return axios
      .post(API_OGC + 'login', {
        username: email,
        password: password,
      })
      .then((response) => {
        if (response) {
          localStorage.setItem('token', response.data.access_token);
        }
        return response.data;
      })
      .catch((err) => {
        console.log('erreur login', err);
        throw err; // Throw the error to be caught in the promise chain
      });
  };
  //change after reset
  const changePassword = (token, password) => {
    console.log(token);
    return axios
      .post(API_URL + "auth/reset/change", {
        token: token,
        password: password,
      })
      .then((response) => {
       
        return response.data;
      })
      .catch((err) => { 
        console.log("erreur chnagepass", err);
        return err;
       
      });
  };
  const resetPassword = (email) => {
    return axios
      .post(API_URL + "auth/reset", {
        email: email,
      })
      .then((response) => {
        if (response) {
        console.log(response.data)
          
        }
        return response.data;
      })
      .catch((err) => {
        console.log("erreur login", err);
        return err;
        
      });
  };
  const getCurrentUser = () => {
    const id = localStorage.getItem("id");
    if (id) return id;
  };
  const profil =  () => {
    //const userId = getCurrentUser();
    return axios
      .get(API_URL + "users/getMyprofil" ,  headersFiles)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  };
  //chnage when i m connected and i know my password
  const PasswordChange =async (data) => {
   const user=  await profil();
   const datas={
    email:user?.email,
    currentPassword:data?.actualPassword,
    newPassword: data?.newPassword,
    confirm_password:data?.confirm
   }
  console.log(data);
    return axios
      .post(API_URL + "auth/changepassword", data, headersFiles)
      .then((response) => {
       
        return response.data;
      })
      .catch((err) => { 
        console.log("erreur chnagepass", err);
        return err;
       
      });
  };
  const AuthService = {
    profil,
    login,
    resetPassword,
    changePassword,
    PasswordChange
   
  };
  
  export default AuthService;