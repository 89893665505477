import React, { useEffect, useState } from "react";

import Sidebar from "../../components/Sidebar";

import Services from "../../services/global.service";
import DatatableUsers from "../../components/DatatableUsers";
import ProfileIcon from "../../assets/images/IdentificationCard.png";
import DropdownItemRadio from "../../components/DropdownItemRadio";
const Users = () => {
  const [data, setdata] = useState();
  const [endpoint, setEndpoint] = useState("users?limit=1000");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [proprietaire, setProprietaire] = useState("");
  const [secteur, setSecteur] = useState("");
  const [zone, setZone] = useState("");
  const [sousZone, setSousZone] = useState("");
  const [enregistrement, setEnregistrement] = useState("");
  const [etat, setEtat] = useState("");
  let list = ["Non affecté", "En marche", "Hors ligne", "Autre état"];
  const loadData = async () => {
    let data = await Services.getAllwithoutocg(endpoint);
    setdata(data);
  };
  const handleSubmit = () => {
    const formData = {
      proprietaire,
      secteur,
      zone,
      sousZone,
      enregistrement,
      etat,
    };
    console.log('formData', formData)
  };
  useEffect(() => {
    let endpointTmp = endpoint.split("?")[0];
    if (localStorage.getItem(endpointTmp.replace("/", "_")) !== null) {
      let data = JSON.parse(localStorage.getItem(endpointTmp.replace("/", "_")));
      console.log("user data : " , data);
      setdata(data);
    }
    loadData();
  }, [endpoint]);

  // useEffect(() => {
  //   Services.getAllwithoutocg(endpoint)
  //   .then(
  //     (response) => {
  //       if (response) {
  //         setdata(response.data);
  //         console.log(response.data);
  //       }
  //       // navigate("/catalogue");
  //     },
  //     (error) => {
  //       console.log(error.response.status);
  //     }
  //   );
  // }, [endpoint]);

  return (
    <>
           <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main className="px-48">
          <h1 className="text-h1 mt-32 mb-32 ps-0">Les utilisateurs</h1>
          <h2 className="text-h2 mt-32 mb-32 ps-0">Mon profil</h2>
            <div className="container-fluid">

            <div className="flexbetween">

            <div className="card">
            <div className="hstack ">
  <img className="rounded-circle shadow-4-strong" alt="avatar2" src="https://mdbcdn.b-cdn.net/img/new/avatars/9.webp" width="120px" />
  <h3 className="card-title  ms-auto">DUPONT Abigael</h3>

  <h4 className="card-text ms-auto border-start" style={{padding:20}}>Chef de secteur</h4>

  <p className="card-text ms-auto border-start"  style={{padding:20}}>Super admin</p>

  <button type="button" className=" ms-auto button-outline-primary" data-bs-toggle="modal" data-bs-target={"#Modifymodal"}>Modifier</button>
</div>
                </div>
                  </div>

              <div className="flexbetween">
                <h2 className="text-h2 mt-32 mb-32 ps-0">Tous les utilisateurs</h2>
                <button
                  type="button"
                  className="button-primary w-auto"
                  data-bs-toggle="modal"
                  data-bs-target={"#addmodal"}
                >
                  Créer un accès
                </button>
              </div>

              <DatatableUsers data={data} />

              <div
                className="modal fade"
                id={"addmodal"}
                tabIndex="-1"
                aria-labelledby={"addmodalLabel"}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md">
                  <div className="modal-content modal-content-p-32">
                    <div className="modal-header border-0 p-0">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body p-0">
                      <h1 className="text-h1">
                        <img src={ProfileIcon} width="42px"/> Ajout d’un utilisateur
                      </h1>
                      <br></br>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-16">
                          <label
                            htmlFor="proprietaire"
                            className="text-label mb-1"
                          >
                            Nom
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Nom"
                            placeholder="Nom"
                            onChange={(event) => {
                             setProprietaire(event.target.value);
                              setError("");
                            }}
                          />
                        </div>

                        <div className="mb-32">
                          <label htmlFor="secteur" className="text-label mb-1">
                          Prénom
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Prenom"
                            placeholder="Prénom"
                            onChange={(event) => {
                              setSecteur(event.target.value);
                              setError("");
                            }}
                          />
                        </div>

                        <div className="mb-32">
                          <label htmlFor="zone" className="text-label mb-1">
                          Poste
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Poste"
                            placeholder="Poste"
                            onChange={(event) => {
                              setZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>
                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                            E-mail
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Email"
                            placeholder="E-mail"
                            onChange={(event) => {
                              setSousZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>

                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                            Accès
                          </label>
                          <DropdownItemRadio
                            list={list}
                            placeholderitem="Accès"
                            radioselector="Accès"
                            onChange={(value) => setEtat(value)}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer justify-content-right border-0 p-0">
                      <button
                        type="button"
                        className="button-outline-primary w-auto"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        className="button-primary w-auto"
                        onClick={() => {
                          setIsOpen(!isOpen);
                          handleSubmit(); // Call the handleSubmit function
                        }}
                      >
                        Valider
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id={"Modifymodal"}
                tabIndex="-1"
                aria-labelledby={"ModifymodalLabel"}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md">
                  <div className="modal-content modal-content-p-32">
                    <div className="modal-header border-0 p-0">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body p-0">
                      <h1 className="text-h1">
                        <img src={ProfileIcon} width="42px"/> Modifier le profil
                      </h1>
                      <br></br>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-16">
                          <label
                            htmlFor="proprietaire"
                            className="text-label mb-1"
                          >
                            Nom
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Nom"
                            placeholder="Nom"
                            onChange={(event) => {
                             setProprietaire(event.target.value);
                              setError("");
                            }}
                          />
                        </div>

                        <div className="mb-32">
                          <label htmlFor="secteur" className="text-label mb-1">
                          Prénom
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Prenom"
                            placeholder="Prénom"
                            onChange={(event) => {
                              setSecteur(event.target.value);
                              setError("");
                            }}
                          />
                        </div>

                        <div className="mb-32">
                          <label htmlFor="zone" className="text-label mb-1">
                          Poste
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Poste"
                            placeholder="Poste"
                            onChange={(event) => {
                              setZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>
                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                            E-mail
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="Email"
                            placeholder="E-mail"
                            onChange={(event) => {
                              setSousZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>
                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                          Mot de passe actuel
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="currentPassword"
                            placeholder="Mot de passe actuel"
                            onChange={(event) => {
                              setSousZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>
                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                          Nouveau mot de passe
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="newPassword"
                            placeholder="Nouveau mot de passe"
                            onChange={(event) => {
                              setSousZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>
                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                          Confirmation du mot de passe
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            id="ConfirmNewPassword"
                            placeholder="Confirmation du mot de passe"
                            onChange={(event) => {
                              setSousZone(event.target.value);
                              setError("");
                            }}
                          />
                        </div>

                        <div className="mb-32">
                          <label
                            htmlFor="sous-zone"
                            className="text-label mb-1"
                          >
                            Accès
                          </label>
                          <DropdownItemRadio
                            list={list}
                            placeholderitem="Accès"
                            radioselector="Accès"
                            onChange={(value) => setEtat(value)}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer justify-content-right border-0 p-0">
                      <button
                        type="button"
                        className="button-outline-primary w-auto"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        className="button-primary w-auto"
                        onClick={() => {
                          setIsOpen(!isOpen);
                          handleSubmit(); // Call the handleSubmit function
                        }}
                      >
                        Valider
                      </button>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Users;
