import React from 'react';
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <>
      <div className="container-fluid auth">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className='banner-start'></div>
          </div>
          <div className="col-md-6 text-center">
            <div className='card text-center w-60'>
              <div className='card-header'>
                <h1 className='text-h1 mb-0'>Première connexion</h1>
              </div>
              <div className='card-body text-start'>
                <form action=''>

                  <div className="mb-16">
                    <label htmlFor="email" className="text-label mb-1">E-mail</label>
                    <input type="email" className="input-form" id="email" placeholder="E-mail" />
                  </div>

                  <div className="mb-16">
                    <label htmlFor="password" className="text-label mb-1">Mot de passe</label>
                    <input type="password" className="input-form" id="password" placeholder="Mot de passe" />
                  </div>

                  <div className="mb-32">
                    <label htmlFor="confirmation" className="text-label mb-1">Confirmation du mot de passe</label>
                    <input type="password" className="input-form" id="confirmation" placeholder="Mot de passe" />
                  </div>

                  <div className="mb-16">
                    <button className='button-primary'>Connexion</button>
                  </div>

                  <div className="text-center">
                    <p htmlFor="password" className="text-label mb-2">Ce n’est pas votre première connexion ?  </p>
                    <Link to="/" className='text-link'>Se connecter</Link>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register