import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";

import Sidebar from "../../components/Sidebar";

import DashboardSearch from "../../components/DashboardSearch";
import DatatableArtiste from '../../components/DatatableArtiste';
import DatatableTitres from "../../components/DatatableTitres";
import DatatableGenre from "../../components/DatatableGenre";
import Services from "../../services/global.service";

const Atabs = () => {

  return (
    <ul className="nav nav-tabs naving" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Artistes
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Titres
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="contact-tab"
          data-bs-toggle="tab"
          data-bs-target="#contact"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          Genres
        </button>
      </li>
    </ul>
  );
};

const Classement = () => {
  const endDateTmp   = new Date();
  const startDateTmp = new Date();
  const startDate = startDateTmp.toISOString().split('T')[0]; 
  const endDate   = endDateTmp.toISOString().split('T')[0]; 

  const filename = startDate + "_" + endDate + "_Export.csv";

  const [data, setdata] = useState([]);
  const [endpoint, setEndpoint] = useState("classement?dateFrom="+ startDate + "&dateTo="+ endDate + "&limit=10000");

  const loadData = async () => {
    let data = await Services.getAll(endpoint);
    setdata(data.response);
  };
  
  useEffect(() => {
    let endpointTmp = endpoint.split("?")[0];
    if (localStorage.getItem(endpointTmp.replace("/", "_")) !== null) {
      let data = JSON.parse(localStorage.getItem(endpointTmp.replace("/", "_")));
      setdata(data.response);
    }
    loadData();
  }, [endpoint]);

//   return (
//   );
// };

// const Classement = () => {
  return (
    <>
      <DashboardSearch data={data} setdata={setdata} />
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main className="px-48">
            <div className="container-fluid">
              <h1 className="text-h1 mt-32 mb-32 ps-0">Les classements</h1>

              <Atabs />

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {/* <div className="d-flex justify-content-between align-items-center mb-32">                  
                    <div>
                      <CSVLink className="text-link" data={data?.artist} separator=";" filename={filename}>
                        Télécharger votre rapport
                      </CSVLink>
                    </div>
                  </div> */}
                  <DatatableArtiste data={data?.artist}/>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {/* <div className="d-flex justify-content-between align-items-center mb-32">                  
                    <div>
                      <CSVLink className="text-link" data={data?.title} separator=";" filename={filename}>
                        Télécharger votre rapport
                      </CSVLink>
                    </div>
                  </div> */}
                  <DatatableTitres data={data?.title} />
                </div>
                <div
                  className="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  {/* <div className="d-flex justify-content-between align-items-center mb-32">                  
                    <div>
                      <CSVLink className="text-link" data={data?.genre} separator=";" filename={filename}>
                        Télécharger votre rapport
                      </CSVLink>
                    </div>
                  </div> */}
                  <DatatableGenre data={data?.genre}/>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Classement;
