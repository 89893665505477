import { useEffect, useState } from "react";

import Sidebar from "../../components/Sidebar";

import Barchar from "../../components/charts/Barchart";
import Piechart from "../../components/charts/Piechart";
import Progressbar from "../../components/charts/Progressbar";
import SmoothedLineChart from "../../components/charts/SmoothedLineChart";

import Waveform from "../../assets/images/icons/waveform.svg";
import Vinylrecord from "../../assets/images/icons/vinylrecord.svg";
import Microphonestage from "../../assets/images/icons/microphonestage.svg";
import Ear from "../../assets/images/icons/ear.svg";
import Musicnotes from "../../assets/images/icons/musicnotes.svg";
import Trendup from "../../assets/images/icons/trendup.svg";
import DashboardSearch from "../../components/DashboardSearch";
import { Link, useNavigate } from "react-router-dom";
import DropdownItemRadio from "../../components/DropdownItemRadio";
import Daterange from "../../components/Daterange";
import Services from "../../services/global.service";
import LoadingScreen from "../../components/Loading";
import axios from "axios";


const Dashboard = () => {
  let list = ["Pop", "R&B", "Rap", "Zouk", "Reggae", "Komba", "House", "Metal"];

  const endDateTmp   = new Date();
  const startDateTmp = new Date();
  const startDate = startDateTmp.toISOString().split('T')[0];
  const endDate   = endDateTmp.toISOString().split('T')[0];

  const [data, setdata] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [endpoint, setEndpoint] = useState("new-dashboard?dateFrom="+ startDate + "&dateTo="+ endDate );
  let [state, setState] = useState("Initial");


  const loadData = async (bool) => {
    setIsLoading(bool)
    let data = await Services.getAllwithoutocg(endpoint);
    setdata(data.response);
    // localStorage.setItem('genre',JSON.stringify(data?.response?.genre))
    // localStorage.setItem('artist',JSON.stringify(data?.response?.artist))
    // localStorage.setItem('title',JSON.stringify(data?.response?.title))
    setIsLoading(false)
  };

  function handleState(newValue) {
    setState(newValue);
 }

  useEffect(() => {
    let bool = true;
    let endpointTmp = endpoint.split("?")[0];
    if (localStorage.getItem(endpointTmp.replace("/", "_")) !== null) {
      let data = JSON.parse(localStorage.getItem(endpointTmp.replace("/", "_")));
      setdata(data.response);
      console.log("recgonized : ",data?.recognized);
      console.log("not recgonized : ",data?.not_recognized);
      bool = false;
    }
    loadData(bool);
  }, [endpoint]);

  return (
    <>
    {/* search = artiste, dateFrom, dateTo  */}
      <DashboardSearch data={data} setdata={setdata} />
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main className="dashboard px-48">
            <div className="container-fluid">
              <div className="row">
                <div className="btn-filter ps-0">
                  <button
                    className="button-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#filterModal"
                  >
                    Filtres
                  </button>
                </div>

                <h1 className="text-h1 mt-32 mb-32 ps-0">
                  Bienvenue sur votre espace
                </h1>

                <div className="card mb-40 overflow-hidden">
                  <div className="card-header">
                    <h2 className="text-h2">
                      <img src={Waveform} className="me-24" alt="" /> Nombre de
                      titres détectés par lieux
                      <span className="badge bg-secondary" style={{float:"right"}}>Total : {data?.DetectionNumber}</span>
                    </h2>
                  </div>
                  <div className="card-body">
                    {isLoading && <LoadingScreen/>}
                    <Barchar data={data?.poi} />
                  </div>
                </div>

                <div className="card progress-chart">
                  <div className="row w-100">
                    <div className="col-lg-4 col-sm-12 ps-0 mb-3">
                      <h3 className="text-h3 mb-24">
                        <img src={Vinylrecord} className="pe-10" alt="" /> Top
                        Titres
                      </h3>
                      <div className="card-body ">
                      {isLoading && <LoadingScreen/>}
                        <Progressbar data={data?.title} />
                      </div>
                      {!isLoading && <div className="text-center p-0">
                        <Link to="classements" className="text-link">
                          Voir tout
                        </Link>
                      </div>}
                    </div>

                    <div className="col-lg-4 col-sm-12 border-start border-color-bleu200 mb-3">
                      <h3 className="text-h3 mb-24">
                        <img src={Microphonestage} className="pe-10" alt="" />{" "}
                        Top Artistes
                      </h3>
                      <div className="card-body">
                      {isLoading && <LoadingScreen/>}
                        <Progressbar data={data?.artist} />
                      </div>
                      {!isLoading && <div className="text-center p-0">
                        <Link to="classements" className="text-link">
                          Voir tout
                        </Link>
                      </div>}
                    </div>

                    <div className="col-lg-4 col-sm-12 border-start border-color-bleu200 pe-0 mb-3">
                      <h3 className="text-h3 mb-24">
                        <img src={Musicnotes} className="pe-10" alt="" /> Top
                        Genres
                      </h3>
                      <div className="card-body">
                      {isLoading && <LoadingScreen/>}
                        <Progressbar data={data?.genre} />
                      </div>
                      {!isLoading && <div className="text-center p-0">
                        <Link to="classements" className="text-link">
                          Voir tout
                        </Link>
                      </div>}
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-40">
                  <div className="col-md-5">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="text-h3">
                          <img src={Ear} className="pe-8" alt="" />
                          Volume des titres
                        </h3>
                      </div>
                      <div className="card-body">
                      {isLoading && <LoadingScreen/>}
                        {data ?
                          <Piechart
                          counts={data?.DetectionNumber}
                          recognized={data?.recognized}
                          not_recognized={data?.not_recognized}
                          />
                        : "" }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="text-h3">
                          <img src={Trendup} className="pe-8" alt="" />
                          Evolution des genres musicaux
                        </h3>
                      </div>
                      <div className="card-body">
                      {isLoading && <LoadingScreen/>}
                        {data?.graphDay ? <SmoothedLineChart data={data?.graphDay} /> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container-fluid */}
          </main>
        </div>
      </div>

      {/* Modal */}

      <div
        className="modal fade"
        id="filterModal"
        tabIndex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content modal-content-p-16">
            <div className="modal-header w-100 border-0 p-0">
              <h1 className="title-h3 mb-32" id="filterModalModalLabel">
                Filtres
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0">
              <div className="form">
                <div className="row gx-0 gy-3">
                  {/* Search input */}
                  <div className="col-lg-12">
                    <input
                      type="text"
                      className="input-form search-icon h-40 ps-5"
                      placeholder="Recherche..."
                    />
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="text"
                      className="input-form search-icon h-40 ps-5"
                      placeholder="Secteur, Sous-zones..."
                    />
                  </div>
                  {/* Search input */}

                  <div className="">
                    <div className="col-md-5 col-12">
                      <DropdownItemRadio
                        list={list}
                        placeholderitem="Genre"
                        radioselector="genre"
                      />
                    </div>
                  </div>
                  <div className="col-auto mb-0">
                    <Daterange />
                  </div>
                  <hr className="mt-24 mb-24" />
                  <div className="col-12 mt-0">
                    <button className="button-primary">Appliquer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
