import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL || "";
const API_URL_NO_OCG = process.env.REACT_APP_WITHOUT_OGC || "";
const token = localStorage.getItem("token");

const exit = () => {
  localStorage.clear();
  window.location.href = "/";
};

const headersFiles = {
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
};

const headersFilesMultipart = {
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
  },
};

const getAll = (endpoint) => {
  return axios.get(API_URL + endpoint, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        endpoint = endpoint.split("?")[0];
        localStorage.setItem(endpoint.replace("/", "_"), JSON.stringify(response.data));
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const getAllwithoutocg = (endpoint) => {
  return axios.get(API_URL_NO_OCG + endpoint, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        endpoint = endpoint.split("?")[0];
        localStorage.setItem(endpoint.replace("/", "_"), JSON.stringify(response.data));
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const getAllByData = (endpoint, data) => {
  return axios.post(API_URL + endpoint, data, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        endpoint = endpoint.split("?")[0];
        localStorage.setItem(endpoint.replace("/", "_"), JSON.stringify(response.data));
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const getOne = (id, endpoint) => {
  return axios.get(API_URL + endpoint + `/${id}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const create = (data, endpoint) => {
  return axios.post(API_URL + endpoint, data, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const createMultipart = (data, endpoint) => {
  return axios.post(API_URL + endpoint, data, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const deleteByid = (id, endpoint) => {
  return axios.post(API_URL + endpoint + `/${id}`, "", {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const update = (data, id, endpoint) => {
  return axios.post(API_URL + endpoint + `/update/${id}`, data, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const updatewithoutID = (data, endpoint) => {
  return axios.post(API_URL + endpoint + `/update`, data, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const getByRole = (endpoint, role) => {
  return axios.get(API_URL + endpoint + `/${role}`, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  }).then(
    (response) => {
      if (response) {
        console.log(response.data);
        return response.data;
      }
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const getAllDatasShow = () => {
  let endpoints = [
    API_URL + "get_all/genre?limit=4000",
    API_URL + "get_all/title?limit=4000",
    API_URL + "get_all/artist?limit=4000",
    API_URL + "get_all/poi?limit=4000",
  ];
  let headers = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    }
  }
  return axios.all(endpoints.map((endpoint) => axios.get(endpoint, headers)))
}

const getFile = (endpoint) => {
  return axios.get(API_URL_NO_OCG + endpoint, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": 'blob',
    },
    responseType: 'arraybuffer'
  }).then(
    (response) => {
      if (response) {
        const url = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `fileName.xlsx`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      return true;
    },
    (error) => {
      console.log(error.response.status);
      if (error.response.status == 401) {
        exit();
      }
    }
  );
};

const Services = {
  getAll,
  getOne,
  create,
  createMultipart,
  deleteByid,
  update,
  updatewithoutID,
  getByRole,
  getAllByData,
  getAllwithoutocg,
  getAllDatasShow,
  getFile
};
export default Services;
