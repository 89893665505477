import React, { Component } from "react";

class DropdownItemRadio extends Component {
  state = {
    clickedOutside: true,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  myRef = React.createRef();

  handleClickOutside = (e) => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ clickedOutside: true });
    }
  };

  handleClickInside = () => this.setState({ clickedOutside: false });

  RadioDdOnclick(el) {
    const hovered = document.querySelectorAll(":hover");

    if (hovered[hovered.length - 1]?.type !== "radio") {
      const x = document.querySelector("#RadioDd");
    }

    document.querySelectorAll("[type=radio]").forEach((r) => {
      r.onclick = (_) => {
        if (r.checked) {
          document.querySelector("#radioSelect" + el).value = r.value;
        }
        // document.querySelector('#RadioDd').style.display = 'none';
      };
    });
  }

  render() {
    const radioselector = this.props.radioselector || '#radioSelect';
    const placeholderitem = this.props.placeholderitem;
    const listItems = this.props.list.map((name, index) => (
      <div key={name} className="w-100">
        <label>
          <input
            type="radio"
            className="form-checkbox"
            value={name}
            name="option"
          />
          <span className="text-label ps-10">{name}</span>
        </label>
      </div>
    ));

    return (
      <div
        className="dropdown"
        id="dropdown"
        ref={this.myRef}
        onClick={this.handleClickInside}
      >
        <div
          className="inputwithicon"
          onClick={this.RadioDdOnclick(radioselector)}
          id="radiobtn"
        >
          <input
            id={"radioSelect" + radioselector}
            className="inputblock form-dropdown dropdown-icon text-btn w-100"
            readOnly
            placeholder={placeholderitem}
          />
        </div>

        <div
          className="list-checkboxes"
          id="RadioDd"
          hidden={this.state.clickedOutside}
        >
          {listItems}
        </div>
      </div>
    );
  }
}

export default DropdownItemRadio;
