import React, { useEffect, useState } from "react";
import Burger from "../assets/images/icons/burger.svg"

function ToggleSidebar() {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        document.body.classList.toggle('sb-sidenav-toggled', isOpen);
      },[isOpen]);

    return (
        <>
            <nav className="sb-topnav sb-toggle navbar navbar-expand h-40 position-absolute pb-0">
                <button className="btn btn-link btn-sm" id="sidebarToggle" onClick={()=> setIsOpen(!isOpen)} href="#!">
                    <img src={Burger} />
                </button>
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                </div>
                
            </nav>
        </>
    );
}

export default ToggleSidebar;